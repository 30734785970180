<template>
    <el-container>
        <el-main>
            <el-row :gutter="20" style="height: 100%">
                <el-col :span="5">
                    <el-aside class="aside">
                        <el-container>
                            <el-header>
                                <el-input placeholder="输入关键字进行过滤" v-model="orgFilterText" clearable></el-input>
                            </el-header>
                            <el-main class="nopadding">
                                <el-tree ref="org" class="menu" node-key="Id" :data="orgList" :props="orgProps" draggable highlight-current :expand-on-click-node="false" :check-strictly="true" show-checkbox :filter-node-method="orgFilterNode" @check-change="handleCheckChange" @node-click="orgClick">
                                    <template #default="{ node }">
                                        <span class="custom-tree-node">
                                            <span class="label"> {{ node.label }}</span>
                                        </span>
                                    </template>
                                </el-tree>
                            </el-main>
                            <el-footer style="height: 51px">
                                <el-button type="primary" size="mini" icon="el-icon-plus" @click="openAddRole"></el-button>
                                <el-button type="primary" size="mini" icon="el-icon-edit" @click="updateRole"></el-button>
                                <el-button type="danger" size="mini" plain icon="el-icon-delete" @click="delRole"></el-button>
                            </el-footer>
                        </el-container>
                    </el-aside>
                </el-col>
                <el-col :span="19">
                    <el-aside class="aside">
                        <el-container>
                            <el-header>
                                <el-input placeholder="输入关键字进行过滤" v-model="menuFilterText" clearable></el-input>
                            </el-header>
                            <el-main class="nopadding">
                                <el-tree ref="menu" class="menu" @check="nodeCheck" :default-checked-keys="checkedkeys" node-key="Id" :data="menuList" :props="menuProps" draggable highlight-current :expand-on-click-node="false" :check-strictly="checkstrictly" show-checkbox :filter-node-method="menuFilterNode" @node-click="menuClick">
                                    <template #default="{ node, data }">
                                        <span class="custom-tree-node">
                                            <span class="label"><i :class="data.MenuPCIcon" style="padding-right: 5px"></i>
                                                {{ node.label }}</span>
                                            <span class="do">
                                                <i class="el-icon-plus" @click.stop="add(node, data)"></i>
                                            </span>
                                        </span>
                                    </template>
                                </el-tree>
                            </el-main>
                        </el-container>
                    </el-aside>
                </el-col>
            </el-row>
        </el-main>
        <el-footer>
            <div style="width: 100%; text-align: center">
                <el-button type="primary" @click="submit" :loading="isSubmit">确认提交</el-button>
            </div>
        </el-footer>
        <el-dialog title="角色" @close="closeRole" v-model="dialogVisible" width="30%">
            <el-form ref="form" :rules="rules" :model="form" label-width="80px">
                <el-form-item prop="RoleName" label="角色名称" :rules="[
            { required: true, message: '请输入角色名称', trigger: 'blur' },
            { max: 20, min: 2, message: '长度在 2 到 20 个字符', trigger: 'blur' },
          ]">
                    <el-input v-model="form.RoleName"></el-input>
                </el-form-item>
                <el-form-item label="是否领导">
                    <el-switch v-model="form.IsLeader"></el-switch>
                    <!-- <el-input type="textarea" v-model="form.Isleader"></el-input> -->
                </el-form-item>
                <el-form-item label="备注">
                    <el-input type="textarea" v-model="form.RemarkInfo"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeRole">取 消</el-button>
                    <el-button type="primary" @click="addRole">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </el-container>
</template>

<script>
export default {
    name: "role",
    data() {
        return {
            menuList: [],
            orgList: [],
            checkstrictly: false,
            checkedkeys: [],
            dialogVisible: false,
            rules: [],
            form: {
                RoleName: "",
                RemarkInfo: "",
                IsLeader: false,
                Id: -1,
            },
            menuProps: {
                label: (data) => {
                    return data.MenuName;
                },
                children: (data) => {
                    return data.Children;
                },
            },
            orgProps: {
                label: (data) => {
                    return data.RoleName;
                },
                children: (data) => {
                    return data.Children;
                },
            },
            menuFilterText: "",
            orgFilterText: "",
            isSubmit: false,
        };
    },
    watch: {
        menuFilterText(val) {
            this.$refs.menu.filter(val);
        },
        orgFilterText(val) {
            this.$refs.org.filter(val);
        },
    },
    mounted() {
        this.LoadData();
    },
    methods: {
        LoadData() {
            this.$API.OrgAuthority.QueryOrgByOrgId.get().then((res) => {
                this.menuList = res;
            });
            this.$API.Role.GetRoleAll.get().then((res) => {
                this.orgList = res;
            });
        },
        //树过滤
        menuFilterNode(value, data) {
            if (!value) return true;
            var targetText = data.MenuName;
            return targetText.indexOf(value) !== -1;
        },
        //树过滤
        orgFilterNode(value, data) {
            if (!value) return true;
            var targetText = data.RoleName;
            return targetText.indexOf(value) !== -1;
        },
        //树点击
        menuClick(data, node) {
            console.log(data);
            console.log(node);
        },
        nodeCheck() {
            //this.checkstrictly = false;
        },
        //树点击
        orgClick(data, node) {
            if (!node.checked) {
                this.handleCheckChange(data, true);
            }
        },
        openAddRole() {
            this.dialogVisible = true;
        },
        updateRole() {
            var roleId = this.$refs.org.getCheckedKeys();
            if (roleId == []) return;
            var roleInfo = this.$refs.org.getNode(roleId[0]);
            this.form = {
                RoleName: roleInfo.data.RoleName,
                RemarkInfo: roleInfo.data.RemarkInfo,
                Id: roleInfo.data.Id,
                IsLeader: roleInfo.data.IsLeader,
            };
            this.dialogVisible = true;
        },
        addRole() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    var that = this;
                    this.$API.Role.AddOrUpdate.post(this.form).then((res) => {
                        if (res.Success) {
                            that.$message.success(res.Message);
                            that.$API.Role.GetRoleAll.get().then((result) => {
                                that.orgList = result;
                            });
                        } else {
                            that.$message.error(res.Message);
                        }
                        that.dialogVisible = false;
                    });
                }
            });
        },
        delRole() {
            var that = this;
            var CheckedNodes = that.$refs.org.getCheckedNodes();
            if (CheckedNodes.length == 0) {
                that.$message.warning("请选择需要删除的项");
                return false;
            }
            var ids = [];
            CheckedNodes.forEach((item) => {
                if (item.Id <= 0) that.$refs.org.remove(item);
                else ids.push(item.Id);
            });
            if (ids.length > 0)
                that.$API.Role.FakeDelete.delete(ids).then((res) => {
                    if (res.Success) {
                        that.$message.success(res.Message);
                        that.$API.Role.GetRoleAll.get().then((result) => {
                            that.orgList = result;
                        });
                    } else {
                        that.$message.error(res.Message);
                    }
                });
        },
        closeRole() {
            this.dialogVisible = false;
            this.form = {
                RoleName: "",
                RemarkInfo: "",
                IsLeader: false,
                Id: -1,
            };
        },
        //组织树单选
        handleCheckChange(data, checked) {
            if (checked) {
                this.$refs.org.setCheckedKeys([data.Id]);
                //获取用户权限
                this.$API.Role.GetMenuByRoleId.get(data.Id).then((res) => {
                    //
                    var that = this;
                    that.$refs.menu.setCheckedNodes([]);
                    res.forEach((element) => {
                        that.$refs.menu.setChecked(element.MenuId, true, false);
                    });
                });
            }
        },
        submit() {
            var orgId = this.$refs.org.getCheckedKeys();
            var menuIds = this.$refs.menu.getCheckedKeys();
            menuIds.push.apply(menuIds, this.$refs.menu.getHalfCheckedKeys());
            console.log(orgId);
            console.log(menuIds);
            var addListOrgAuthority = {
                OrgId: orgId[0],
                MenuIds: menuIds,
            };
            this.$API.Role.AddRoleAuthor.post(addListOrgAuthority).then(
                (res) => {
                    console.log(res);
                    this.$notify({
                        title: "成功",
                        message: "操作成功！",
                        type: "success",
                    });
                }
            );
        },
    },
};
</script>

<style scoped>
.el-main {
    padding: 0px;
}
.custom-tree-node {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 24px;
    height: 100%;
}
.custom-tree-node .label {
    display: flex;
    align-items: center;
    height: 100%;
}
.custom-tree-node .label .el-tag {
    margin-left: 5px;
}
.custom-tree-node .do {
    display: none;
}
.custom-tree-node .do i {
    margin-left: 5px;
    color: #999;
    padding: 5px;
}
.custom-tree-node .do i:hover {
    color: #333;
}

.custom-tree-node:hover .do {
    display: inline-block;
}
.icon {
    padding-right: 10px;
}
.aside {
    display: inline-block;
    height: 100%;
    width: 100%;
}
</style>
